import React, { useEffect, useState } from "react";
import "./styles/Header.scss";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { menuList } from "../utils/menu";
import { MoveToTop } from "../hooks/MoveToTop";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [inversion, setInversion] = useState(false);
  const [scrollDir, setScrollDir] = useState("up");
  const [hamburger, setHamburger] = useState(false);
  const [drop, setDrop] = useState<boolean[]>([]);
  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;
    const updateScrollDir = () => {
      const scrollY = window.scrollY;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);
  useEffect(() => {
    if (location.pathname === "/news") setInversion(true);
    else setInversion(false);
    MoveToTop();
  }, [location]);
  useEffect(() => {
    const array = [];
    for (let i = 0; menuList.length > i; i++) {
      if (menuList[i].titleLink === "") array.push(false);
    }
    setDrop(array);
  }, [menuList]);
  const handleHamburger = () => {
    setHamburger(!hamburger);
  };
  const handleMobileMenu = (title: string) => {
    const index = menuList.findIndex((menu: { title: string; titleLink: string; list: string[]; listLink: string }) => {
      return menu.title === title;
    });
    const newDrop = [...drop];
    newDrop[index] = !newDrop[index];
    setDrop(newDrop);
  };
  const openPdf = () => {
    window.open("/2024_Architeco Group PR_GO_Eng2.pdf", "_blank");
  };
  return (
    <>
      <div id="header_wrap">
        <div className="d_wrap" style={{ height: 80, top: `${scrollDir === "up" ? "0px" : "-80px"}` }}>
          <div className={`header ${inversion ? "inversion" : ""}`}>
            <div className="logo_wrap">
              <NavLink to="/">
                <Logo className="logo" />
              </NavLink>
            </div>
            <div className="nav_wrap">
              <ul className="menu">
                {menuList.map((menu: { title: string; titleLink: string; list: string[]; listLink: string }, index: number) => {
                  return menu.listLink !== "" ? (
                    <li key={`${menu.title}_${index}`}>{menu.title}</li>
                  ) : (
                    <li
                      key={`${menu.title}_${index}`}
                      onClick={() => {
                        window.open(menu.titleLink);
                      }}
                    >
                      <span>
                        {menu.title} <img src="/imgs/arrow_type_03.png" alt="" />
                      </span>
                    </li>
                  );
                })}
              </ul>
              <button onClick={openPdf}>
                <img src="imgs/eng.png" alt="eng" />
                ENG
              </button>
            </div>
          </div>
          <div className="child_menu_wrap">
            {menuList.map((menu: { title: string; titleLink: string; list: string[]; listLink: string }, index: number) => {
              return menu.list.length > 0 ? (
                <div className="child_menu" key={`${menu.title}_${index}`}>
                  <div className="name">{menu.title}</div>
                  <ul>
                    {menu.list.map((list: string, index2: number) => {
                      return (
                        <li key={`${list}_${index2}`}>
                          <span
                            onClick={() => {
                              navigate(menuList[index].listLink, { state: { tap: index2 } });
                            }}
                          >
                            {list}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null;
            })}

            <div className="blank"></div>
          </div>
        </div>
        <div className="m_wrap" style={{ height: 44, top: `${hamburger ? "0px" : scrollDir === "up" ? "0px" : "-44px"}` }}>
          <div className="m_header">
            <div className="logo_wrap">
              <NavLink to="/">
                <Logo className="logo" />
              </NavLink>
            </div>
            <div className="ham_wrap" onClick={handleHamburger}>
              {hamburger ? <img src="/imgs/x.png" alt="x" /> : <img src="/imgs/hamburger.png" alt="hamubrger" />}
            </div>
          </div>
          {hamburger ? (
            <>
              <div className="m_menu_wrap">
                {menuList.map((menu: { title: string; titleLink: string; list: string[]; listLink: string }, index: number) => {
                  return (
                    <ul key={`${menu.title}_${index}`}>
                      {menu.titleLink === "" ? (
                        <>
                          <div
                            className="title"
                            onClick={() => {
                              handleMobileMenu(menu.title);
                            }}
                          >
                            <div className="text">{menu.title}</div>
                            <div className={`btn${drop[index] ? " ative" : ""}`}>
                              <img src="/imgs/arrow_type_04.png" alt="arrow" />
                            </div>
                          </div>
                          {drop[index] ? (
                            <div className={`drop_wrap d_ative`}>
                              {menu.list.map((text: string, index2: number) => {
                                return (
                                  <li
                                    key={`${text}_${index2}`}
                                    onClick={() => {
                                      navigate(menuList[index].listLink, { state: { tap: index2 } });
                                      setHamburger(false);
                                    }}
                                  >
                                    {text}
                                  </li>
                                );
                              })}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="title">
                          <div
                            className="text"
                            onClick={() => {
                              window.open(menu.titleLink);
                            }}
                          >
                            {menu.title}
                            <img src="/imgs/arrow_type_03.png" alt="arrow" />
                          </div>
                        </div>
                      )}
                    </ul>
                  );
                })}
                <button onClick={openPdf}>
                  <img src="imgs/eng.png" alt="eng" />
                  ENG
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Header;

{
  /* {menuList.map((menu: { title: string; titleLink: string; list: string[]; listLink: string }, index: number) => {
                return (
                  <ul key={`${menu.title}_${index}`}>
                    {menu.titleLink === "" ? (
                      <>
                        <div
                          className="title f_d"
                          onClick={() => {
                            handleMobileMenu(menu.title);
                          }}
                        >
                          <div className="text">{menu.title}</div>
                          <div className={`btn ${drop[index] ? "ative" : ""}`}>
                            <img src="/imgs/arrow_type_04.png" alt="arrow" />
                          </div>
                        </div>
                        <div className={`drop_wrap ${drop[index] ? "d_ative" : ""}`}>
                          {menu.list.map((text: string, index2: number) => {
                            return (
                              <li
                                key={`${text}_${index}`}
                                onClick={() => {
                                  navigate(menuList[index].listLink, { state: { tap: index2 } });
                                  setHamburger(false);
                                }}
                              >
                                {text}
                              </li>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <div className="title">
                        <div
                          className="text"
                          onClick={() => {
                            window.open(menu.titleLink);
                          }}
                        >
                          {menu.title}
                          <img src="/imgs/arrow_type_03.png" alt="arrow" />
                        </div>
                      </div>
                    )}
                  </ul>
                );
              })} */
}
